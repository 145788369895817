@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');

.navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 0 2vw;
    box-shadow: 0 0.2vw 0.3vw -0.05vw rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 2vw;
    border-bottom-right-radius: 2vw;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-sizing: border-box;
}

.navbar-left {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.navbar-logo {
    width: 5vw;
    height: auto;
    margin-right: 1vw;
    display: flex;
    align-items: center;
}

.navbar-title {
    margin: 0;
    color: #85b9df;
    font-size: 1.3vw;
    white-space: nowrap;
    font-family: 'Playfair Display', serif;
}

.navbar-toggle {
    background: none;
    border: none;
    display: none;
    cursor: pointer;
    color: #78a7c9;
    font-size: 2vw;
    align-self: center;
}

.navbar-nav {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    align-items: center;
}

.navbar-nav.show {
    display: flex;
}

.navbar-nav a {
    text-decoration: none;
    color: #333;
    font-size: 1.2vw;
    margin-right: 2vw;
}

.navbar-nav a.active {
    color: #78a7c9;
}

.talk-button {
    background-color: #85b9df;
    border: none;
    padding: 0.5vw 0.8vw;
    text-transform: uppercase;
    border-radius: 0.5vw;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: 2vw;
    font-size: 1vw;
    align-self: center;
}

.talk-button a {
    color: white;
    margin: 0;
    text-decoration: none;
}

.talk-button:hover {
    background-color: #78a7c9;
}

.talk-button.active {
    background-color: #78a7c9;
}

/*-----------------------responsive----------------------*/
@media (max-width: 992px) {
    .navbar-toggle {
        display: block;
    }

    .navbar-nav {
        display: none;
        flex-direction: column;
        position: absolute;
        background-color: white;
        right: 0;
        top: 100%;
        width: 100%;
        margin-top: -0.5vw;
        z-index: 900;
        box-shadow: 0 0.2vw 0.3vw -0.05vw rgba(0, 0, 0, 0.1);
        border-bottom-left-radius: 2vw;
        border-bottom-right-radius: 2vw;
        padding-bottom: 0.7vw;
    }

    .navbar-nav.show {
        display: flex;
    }

    .navbar-nav::before {
        content: '';
        display: block;
        width: 1.5vw;
        height: 3vw;
        background-color: white;
        position: absolute;
        top: -2.5vw;
        left: 0;
    }

    .navbar-nav::after {
        content: '';
        display: block;
        width: 1.5vw;
        height: 3vw;
        background-color: white;
        position: absolute;
        top: -2.5vw;
        left: 98.5vw;
    }

    .navbar-title {
        font-size: 1.5vw;
    }

    .talk-button {
        padding: 1vw 2vw;
        padding-top: 1.1vw;
        font-size: 1.3vw;
    }
}