.home-container {
  width: 100%;
  position: relative;
  text-align: center;
}

.home-image {
  width: 100%;
  height: auto;
  display: block;
  margin-top: 2vw;
}

.home-overlay-text h1 {
  position: absolute;
  top: 4.5vw;
  left: 0;
  right: 0;
  color: #85b9df;
  text-align: center;
  font-size: 4vw;
  z-index: 1;
  max-width: 90%;
  border-radius: 33px;
  padding-left: 25px;
  padding-right: 20px;
  margin: auto;
  white-space: nowrap;
}

.home-overlay-text .highlight {
  color: #85b9df;
}

.home-overlay-text p {
  position: absolute;
  top: 9vw;
  left: 0;
  right: 0;
  color: #333;
  padding: 0.7vw;
  text-align: center;
  font-size: 1.1vw;
  border-radius: 33px;
  max-width: 55%;
  box-sizing: border-box;
  margin: auto;
}

/*-----------------------responsive----------------------*/
@media (max-width: 768px) {
  .home-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .home-image {
    margin-top: 10vw;
  }

  .home-overlay-text h1 {
    font-size: 5.5vw;
    margin-top: -7vw;
  }

  .home-overlay-text p {
      font-size: 1.5vw;
      max-width: 60%;
      margin-top: -5vw;
      margin-bottom: 2vw;
  }

  .home-overlay-text {
      order: -1;
  }
}