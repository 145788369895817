@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');

html, body {
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

h1 {
  font-family: 'Playfair Display', serif;
}