.map-container {
    background-color: white;
    border-top-left-radius: 2vw;
    border-top-right-radius: 2vw;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 -0.2vw 0.3vw -0.05vw rgba(0, 0, 0, 0.1);
    padding: 1.1vw; /*padding cijelog componenta*/
    padding-bottom: 3.5vw; /*componenta dole*/
    margin-top: -1.7vw; /*minus gore za zaobljenje component pozadine*/
}

.map-container h1 {
    text-align: left;
    width: 100%;
    color: #333;
    margin-top: 2.4vw; /*naslov componenta gore*/
    margin-bottom: 1.7vw; /*naslov componenta dole*/
    margin-left: 14vw; /*naslov componenta livo*/
    font-size: 2.4vw;
}

.map-container .highlight {
    color: #78a7c9;
}

.map-container iframe {
    border-radius: 1vw;
    width: 70%;
    max-height: 90vw;
    height: 40vw;
    outline: none !important;
    border: none !important;
}

/*-----------------------responsive----------------------*/
@media (max-width: 768px) {
    .map-container iframe{
        width: 70% !important;
        height: auto;
        margin: 0;
        /*margin-left: 5vw !important; /*mora bit jednako ka i naslov componenta livo*/
        /*margin-right: 5vw !important; /*mora bit jednako ka i naslov componenta livo*/
    }

    .map-container h1 {
        font-size: 2.6vw;
    }
}