.amenities-container {
    background-color: #99c8e2;
    border-radius: 2vw;
    width: 100%;
    padding: 1.1vw; /*padding cijelog componenta*/
    padding-bottom: 3.5vw; /*componenta dole*/
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 15vw;
}

.amenities-container h1 {
    margin-top: 2.4vw; /*naslov componenta gore*/
    margin-bottom: 1.7vw; /*naslov componenta dole*/
    margin-left: 14vw; /*naslov componenta livo*/
    width: 100%;
    text-align: left;
    font-size: 2.4vw;
    color: #333;
}

.amenities-container .highlight {
    color: white;
}

.amenities-carousel-container {
    width: 70%;
    margin: auto;
}

.amenities-card {
    background-color: white;
    border-radius: 0.7vw;
    padding-top: 1.2vw;
    padding-bottom: 0.8vw;
    padding-left: 1.25vw;
    padding-right: 1.2vw;
    box-sizing: border-box;
    text-align: left;
    box-shadow: 0 0.2vw 0.3vw rgba(0, 0, 0, 0.1);
    margin: 0;
}

.amenities-icon {
    display: block;
    width: 5.5vw;
    height: auto;
    margin-right: auto;
    margin-left: auto;
}

.amenities-room-title {
    font-size: 1.3vw;
    color: #78a7c9;
    text-align: center;
    margin: 0;
    margin-bottom: 0.7vw;
}

.amenities-item {
    font-size: 1vw;
    color: #333;
    margin: 0;
    margin-bottom: 0.3vw;
}

.amenities-item .highlight {
    color: #78a7c9;
}

/*----------------------arrows----------------------*/

.amenities-carousel-container .slick-prev,
.amenities-carousel-container .slick-next {
    top: 30%;
    z-index: 1;
}

.amenities-carousel-container .slick-slide {
    padding: 0 0.7vw;
}

.amenities-carousel-container .slick-prev:before,
.amenities-carousel-container .slick-next:before {
    color: white;
    font-size: 1.8vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.amenities-carousel-container .slick-prev {
    left: -5vw;
}

.amenities-carousel-container .slick-next {
    right: -5vw;
}

/*-----------------------responsive----------------------*/
@media (max-width: 768px) {
    .amenities-container h1 {
        font-size: 2.6vw;
    }
    
    .amenities-room-title {
        font-size: 1.6vw;
    }

    .amenities-item {
        font-size: 1.3vw;
    }
}