.photos-container {
    background-color: white;
    box-shadow: 0 -0.2vw 0.3vw -0.05vw rgba(0, 0, 0, 0.1);
    width: 100%;
    border-radius: 2vw;
    padding: 1.1vw; /*padding cijelog componenta*/
    padding-bottom: 3.5vw; /*componenta dole*/
    margin-top: -1.7vw; /*minus gore za zaobljenje component pozadine*/
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.photos-container h1 {
    text-align: left;
    width: 100%;
    margin-top: 2.4vw; /*naslov componenta gore*/
    margin-bottom: 1.7vw; /*naslov componenta dole*/
    margin-left: 14vw; /*naslov componenta livo*/
    color: #333;
    font-size: 2.4vw;
}

.photos-container .highlight {
    color: #78a7c9;
}

.carousel-container {
    width: 70%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    position: relative;
}

.image-container {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.carousel-image {
    width: 100%;
    height: auto;
    border-radius: 1vw;
    box-shadow: 0 0.2vw 0.3vw -0.05vw rgba(0, 0, 0, 0.1);
}

.image-description {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 1vw 0.7vw;
    border-bottom-left-radius: 1vw;
    border-bottom-right-radius: 1vw;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 10;
}

.apartmentImg-description-title {
    font-size: 1.6vw;
    color: #333;
}

.apartmentImg-description-text {
    font-size: 1.2vw;
    color: #333;
}

.image-container:hover .carousel-image {
    box-shadow: 0 0vw 0vw 0vw rgba(0,0,0,0);
}

.image-container:hover .image-description {
    opacity: 1;
}

.image-container:hover .image-hover-indicator {
    opacity: 1;
}

.image-hover-indicator {
    color: #5e839e;
    font-size: 1.3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -0.6vw;
    margin-left: -0.4vw;
    margin-bottom: 0.3vw;
}

.image-hover-indicator:hover {
    color: #333;
}

.icon-spacing {
    margin-right: 0.4vw;
}

/*----------------------arrows----------------------*/
.slick-prev:before, .slick-next:before {
    font-size: 1.9vw;
    color: #78a7c9;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slick-prev {
    left: -5vw;
}

.slick-next {
    right: -5vw;
}

.slick-arrow.slick-disabled {
    opacity: 0 !important;
    visibility: hidden !important;
}

/*----------------------popup----------------------*/
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: rgba(250, 250, 250, 1);
    padding-top: 2.4vw; /*modal gore*/
    padding-bottom: 1vw; /*modal dole*/
    padding-left: 0.5vw; /*mora bit jednako ka i modal scrollbar debljina*/
    padding-right: 0;
    border-radius: 2vw;
    position: relative;
    max-width: 60%;
    width: auto;
    max-height: 93vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-title {
    text-align: left;
    margin-top: 0.5vw; /*modal gore do naslova*/
    margin-left: 5.9vw; /*modal naslov livo*/
    margin-bottom: 1vw; /*modal naslov doli*/
    font-size: 1.6vw;
    color: #333;
    width: 60%;
}

.modal-content img {
    max-width: 95%;
    height: auto;
    border-radius: 1vw;
    margin-bottom: 1.6vw;
    display: block;
    margin-left: 5.9vw; /*modal slika livo mora bit jednako ka i modal naslov livo*/
    margin-right: 5.9vw;
}

.close-button {
    position: absolute;
    top: 1.15vw; /*modal close gore*/
    right: 1.3vw; /*modal close desno*/
    font-size: 2.2vw;
    cursor: pointer;
}

/*-----------------------scrollbar----------------------*/
.modal-content::-webkit-scrollbar {
    width: 0.5vw; /*modal scrollbar debljina*/
    background-color: transparent;
}

.modal-content::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0.5vw;
    margin: 2.4vw 0; /*mora bit jednako ka i modal gore*/
}

.modal-content::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.5vw;
}

.modal-content::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

/*-----------------------responsive----------------------*/
@media (max-width: 768px) {
    .carousel-container {
        width: 70% !important;
        height: auto;
        margin: 0;
        /*margin-left: 5vw !important; /*mora bit jednako ka i naslov componenta livo*/
        /*margin-right: 5vw !important; /*mora bit jednako ka i naslov componenta livo*/
    }

    .photos-container h1 {
        font-size: 2.6vw;
    }

    .apartmentImg-description-title {
        font-size: 1.8vw;
    }
    
    .apartmentImg-description-text {
        font-size: 1.4vw;
    }

    .image-hover-indicator {
        font-size: 1.5vw;
    }

    .modal-content {
        max-width: 90%;
        height: auto;
    }
}