.footer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #333;
    padding: 1.1vw; /*padding cijelog componenta*/
    padding-left: 2.1vw;
    padding-right: 2.1vw;
    color: #fff;
}

.footer-left, .footer-right {
    flex: 1;
    display: flex;
    align-items: center;
}

.footer-logo {
    width: 15vw;
    height: auto;
    display: flex;
    align-items: center;
}

.footer-nav p {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    text-align: center;
    margin: 0;
}

.footer-copyright {
    font-size: 1.1vw;
    margin: 0;
    padding-top: 2.4vw;
    padding-bottom: 0.7vw;
}

.footer-adress, .footer-email {
    font-size: 0.9vw;
    color: #ccc;
    margin: 0;
}

.footer-email {
    text-decoration: none;
}

.footer-email:hover {
    text-decoration: underline;
}


.footer-right {
    justify-content: flex-end;
}

/*-----------------------responsive----------------------*/
@media (max-width: 768px) {
    .footer-copyright {
        font-size: 1.3vw;
    }

    .footer-adress, .footer-email {
        font-size: 1.1vw;
    }
}