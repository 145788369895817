.connect-container {
    background-color: white;
    width: 100%;
    padding: 1.1vw; /*padding cijelog componenta*/
    padding-bottom: 3.5vw; /*componenta dole*/
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.connect-container h1 {
    text-align: left;
    width: 100%;
    color: #333;
    margin-top: 2.4vw; /*naslov componenta gore*/
    margin-bottom: 1.7vw; /*naslov componenta dole*/
    margin-left: 14vw; /*naslov componenta livo*/
    font-size: 2.4vw;
}

.connect-container .highlight {
    color: #78a7c9;
}

.connect-container p {
    width: 69.5%;
    height: auto;
    color: #333;
    text-align: justify;
    text-justify: inter-word;
    margin-bottom: 1vw;
    font-size: 1.1vw;
}

.connect-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
}

.connect-form-group {
    display: flex;
    justify-content: space-between;
    width: 70%;
    height: auto;
    gap: 1vw;
    font-size: 1.1vw;
}

.connect-container input, .connect-container textarea {
    width: 70%;
    height: auto;
    padding: 1.3vw;
    padding-left: 1.5vw;
    margin-bottom: 1.2vw;
    border: 0.05vw solid #ccc;
    border-radius: 1vw;
    box-sizing: border-box;
    display: block;
}

.connect-container input:focus, .connect-container textarea:focus {
    outline: 0.05vw solid #85b9df;
}

.connect-container textarea {
    height: 13vw;
    font-size: 1.1vw;
}

.connect-button {
    padding: 1vw 3.5vw;
    background-color: #85b9df;
    border: none;
    border-radius: 0.75vw;
    color: white;
    font-size: 1.2vw;
    cursor: pointer;
    transition: background-color 0.3s;
}

.connect-button:hover {
    background-color: #78a7c9;
}

/*----------------------popup----------------------*/

.message-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050;
}

.message-modal-content {
    width: 30%;
    padding: 3.3vw;
    margin-bottom: 2vw;
    border-radius: 1vw;
    background-color: white;
    position: relative;
    box-shadow: 0 0.2vw 0.3vw rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.message-modal-close {
    position: absolute;
    top: 0.3vw;
    right: 0.7vw;
    border: none;
    background: none;
    font-size: 1.6vw;
    cursor: pointer;
}

.message-modal-body {
    text-align: center;
    font-size: 1.25vw;
    color: #333;
}

/*-----------------------responsive----------------------*/
@media (max-width: 768px) {
    .connect-container h1 {
        font-size: 2.6vw;
    }

    .connect-container p {
        width: 69.5%;
        height: auto;
        font-size: 1.4vw;
    }

    .connect-form-group {
        width: 70%;
        height: auto;
        font-size: 1.4vw;
    }

    .connect-container textarea {
        width: 70%;
        height: auto;
        height: 18vw;
        font-size: 1.4vw;
    }

    .message-modal-content {
        width: 70%;
        height: auto;
        padding: 5.3vw;
    }

    .message-modal-close {
        font-size: 2vw;
    }

    .message-modal-body {
        font-size: 1.7vw;
    }
    
}