.host-container {
    background-color: white;
    width: 100%;
    border-radius: 2vw;
    padding: 1.1vw; /*padding cijelog componenta*/
    padding-bottom: 3.5vw; /*componenta dole*/
    margin-top: -1.7vw; /*minus gore za zaobljenje component pozadine*/
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0.2vw 0.3vw -0.05vw rgba(0, 0, 0, 0.1);
}

.host-container h1 {
    text-align: left;
    width: 100%;
    color: #333;
    margin-top: 2.4vw; /*naslov componenta gore*/
    margin-bottom: 1.7vw; /*naslov componenta dole*/
    margin-left: 14vw; /*naslov componenta livo*/
    font-size: 2.4vw;
}

.host-container .highlight {
    color: #78a7c9;
}

.host-intro {
    width: 71%;
    display: flex;
    align-items: center;
}

.badge-image {
    width: 5.2vw;
    height: auto;
}

.host-intro p{
    width: 100%;
    font-size: 1vw;
    text-align: justify;
    line-height: 1.6;
    margin: 0;
}

.host-tanja-image {
    width: 70%;
    height: auto;
    margin-top: 5vw;
}

.host-text {
    flex-direction: column;
    text-align: justify;
    justify-content: left;
    z-index: 20;
    width: 70%;
    margin: 0;
    margin-top: -42vw; /*rucno namisteno da bude priko slike*/
    margin-left: 10.5vw;
    font-size: 1vw;
    color: #333;
    font-style: italic;
    line-height: 1.6;
    min-height: 20vw;
}

.host-text-naslov {
    font-size: 1.4vw;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 1vw;
}

.host-text p {
    width: 68%;
    margin-bottom: 1vw;
}

.host-button {
    padding: 0.7vw 1.3vw;
    background-color: #85b9df;
    border: none;
    border-radius: 0.5vw;
    color: white;
    font-size: 1vw;
    cursor: pointer;
    transition: background-color 0.3s;
}

.host-button:hover {
    background-color: #78a7c9;
}

.host-zadnje {
    align-items: center;
    z-index: 20;
    margin-top: 21vw; /*rucno namisteno da bude ispod slike*/
    font-size: 1.2vw;
    font-weight: bold;
    font-style: italic;
    color: #333;
}

/*-----------------------responsive----------------------*/
@media (max-width: 768px) {
    .host-container h1 {
        font-size: 2.6vw;
    }

    .host-intro {
        width: 71%;
    }

    .host-intro p {
        font-size: 1.3vw;
    }

    .host-tanja-image {
        width: 70%;
        margin-top: 12vw;
    }

    .host-text {
        width: 70%;
        margin-top: -49vw; /*rucno namisteno da bude priko slike*/
    }

    .host-text-naslov {
        font-size: 1.7vw;
    }

    .host-text p{
        width: 85%;
        font-size: 1.3vw;
    }

    .host-button {
        font-size: 1.3vw;
        padding: 1vw 1.6vw;
    }

    .host-zadnje {
        font-size: 1.5vw;
        margin-top: 26.5vw; /*rucno namisteno da bude ispod slike*/
    }
}