.features-container {
    background-color: white;
    width: 100%;
    padding: 1.1vw; /*padding cijelog componenta*/
    padding-bottom: 3.5vw; /*componenta dole*/
    margin-top: -1.7vw; /*minus gore za component ispod zaobljene gore*/
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.features-container h1 {
    margin-top: 4.1vw; /*naslov componenta gore plus margin-top za component ispod zaobljene gore*/
    margin-bottom: 1.7vw; /*naslov componenta dole*/
    margin-left: 14vw; /*naslov componenta livo*/
    width: 100%;
    text-align: left;
    font-size: 2.4vw;
    color: #333;
}

.features-container .highlight {
    color: #78a7c9;
}

.features-card {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 70%;
    background: white;
    border-radius: 0vw;
    margin-bottom: 3.5vw; /*naslov componenta dole*/
    overflow: visible;
}

.features-card:last-child {
    margin-bottom: 2vw;
}

.features-text {
    max-width: 46%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: justify;
    text-justify: inter-word;
    margin-top: 0.5vw;
}

.features-image {
    width: 46%;
    height: auto;
    border-radius: 0.7vw;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.features-image:hover {
    cursor: pointer;
    transform: scale(1.02);
}

.features-text .features-text-naslov {
    font-size: 1.8vw;
    margin-bottom: 1vw;
    text-align: left;
}

.features-text p {
    font-size: 1vw;
    color: #333;
    margin: 0;
    line-height: 1.5;
}

.features-text .highlight {
    color: #333;
    font-style: italic;
}

.features-button {
    padding: 0.5vw 0.8vw;
    background-color: #85b9df;
    border: none;
    border-radius: 0.5vw;
    color: white;
    font-size: 1.05vw;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 0.5vw;
    max-width: 30%;
    white-space: nowrap;
}

.features-button:hover {
    background-color: #78a7c9;
}

/*----------------------popup----------------------*/
.features-container .modal-content {
    background: rgba(250, 250, 250, 1);
    padding: 0;
    padding-top: 2.4vw;
    padding-bottom: 1.2vw;
    border-radius: 2vw;
    position: relative;
    max-width: 90%;
    width: auto;
    max-height: 93vh;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
}

.features-container .modal-content img {
    max-height: 70vh;
    max-width: 80vh;
    height: auto;
    width: auto;
    border-radius: 1vw;
    padding: 0;
    margin-bottom: 2vw;
    box-sizing: border-box;
}

/*-----------------------responsive----------------------*/
@media (max-width: 768px) {
    .features-container h1 {
        font-size: 2.6vw;
    }

    .features-card {
        flex-direction: column;
        align-items: center;
        width: 70%;
    }

    .features-image, .features-text {
        width: 100% !important;
        margin-bottom: 2vw;
    }

    .features-image {
        order: -1;
    }

    .features-text {
        flex: 1 1 100%;
        max-width: none !important;
    }

    .features-text .features-text-naslov {
        font-size: 2.1vw;
    }

    .features-text p {
        font-size: 1.3vw;
    }

    .features-button {
        padding: 0.8vw 1.7vw;
        font-size: 1.35vw;
        margin-bottom: 5vw;
    }
}