.reviews-container {
    background-color: #99c8e2;
    width: 100%;
    padding: 1.1vw; /*padding cijelog componenta*/
    padding-bottom: 3.5vw; /*componenta dole*/
    margin-top: -1.7vw; /*minus gore za component ispod zaobljene gore*/
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 15.6vw;
}

.reviews-container h1 {
    text-align: left;
    width: 100%;
    margin-top: 4.1vw; /*naslov componenta gore plus margin-top za component ispod zaobljene gore*/
    margin-bottom: 1.7vw; /*naslov componenta dole*/
    margin-left: 14vw;
    color: #333;
    font-size: 2.4vw;
}

.reviews-container .highlight {
    color: white;
}

.reviews-carousel-container {
    width: 70%;
    margin: auto;
}

.review-card {
    background-color: white;
    border-radius: 0.7vw;
    padding-top: 0.8vw;
    padding-bottom: 0.3vw;
    padding-left: 1.4vw;
    padding-right: 1.2vw;
    box-sizing: border-box;
    text-align: left;
    box-shadow: 0 0.2vw 0.3vw rgba(0,0,0,0.1);
}

.review-quotations {
    width: 2vw;
    height: auto;
    margin-top: 0.3vw;
    margin-bottom: 0.4vw;
    margin-left: -0.2vw;
}

.review-text {
    font-size: 1vw;
    color: #333;
    font-style: italic;
    margin: 0;
    margin-bottom: 0.9vw;
}

.review-text .smajlici{
    font-style: normal;
}

.review-author {
    font-size: 0.88vw;
    color: #333;
    font-weight: bold;
    margin: 0;
}

.review-stars-date {
    display: flex;
    align-items: center;
    margin: 0;
    margin-top: 0.1vw;
    margin-bottom: 0.7vw;
}

.review-stars {
    width: 5.3vw;
    height: auto;
    margin: 0;
    margin-left: -0.3vw;
    margin-right: 0.2vw;
}

.review-date {
    font-size: 0.8vw;
    color: #333;
    margin: 0;
}

/*----------------------airbnb link----------------------*/
.reviews-airbnb-link {
    width: 100%;
    text-align: center;
    margin-top: 2vw;
    margin-bottom: 1.7vw; /*dodatno na zadnji element componenta dole jer je iznad njega od dole zaobljeni*/
    font-size: 1.1vw;
}

.reviews-airbnb-link a {
    color: white;
}

.reviews-airbnb-link a:hover {
    text-decoration: none;
}

/*----------------------arrows----------------------*/

.reviews-carousel-container .slick-prev,
.reviews-carousel-container .slick-next {
    top: 25%;
    z-index: 1;
}

.reviews-carousel-container .slick-slide {
    padding: 0 0.6vw;
}

.reviews-carousel-container .slick-prev:before,
.reviews-carousel-container .slick-next:before {
    font-size: 1.8vw;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reviews-carousel-container .slick-prev {
    left: -5vw;
}

.reviews-carousel-container .slick-next {
    right: -5vw;
}

/*-----------------------responsive----------------------*/
@media (max-width: 768px) {
    .reviews-carousel-container {
        width: 70%;
        margin: auto;
    }

    .reviews-container h1 {
        font-size: 2.6vw;
    }

    .review-text {
        font-size: 1.3vw;
        color: #333;
    }

    .review-author {
        font-size: 1.18vw;
    }
    
    .review-date {
        font-size: 1.1vw;
    }
}